import axios, { AxiosRequestConfig } from 'axios';
import { routerPush } from '@/service/kplaceRouter';

const api = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});

api.interceptors.request.use((req) => {
  req.headers = req.headers || {};
  const token = localStorage.getItem('token');
  const sellerId = localStorage.getItem('sellerId');
  if (token && sellerId) {
    req.headers.sellerId = sellerId;
  }
  if (token && !req.url?.includes('oauth/token')) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

api.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    routerPush('/login');
  }
  return error;
});

const get = (url: string, config?: AxiosRequestConfig): unknown => api.get(url, config);

const post = (url: string, data?: unknown, config?: AxiosRequestConfig): unknown => api.post(url, data, config);

const put = (url: string, data?: unknown): unknown => api.put(url, data);

const remove = (url: string): unknown => api.delete(url);

export {
  get, post, put, remove,
};
